<template>
  <components
    :is="isPage ? 'SdPage' : 'div'"
    :style="isRenderedFromUnitPage ? 'padding: 0;' : ''"
    class="property-info-box"
  >
    <template v-if="unit.active_lease_period !== null && !isPublishListing">
      <div
        v-if="$viewport.gt.md"
        class="property-info-header font-17 font-weight-strong mb-4"
      >
        <i class="sdicon-magic" />
        <span>AI Rent Analysis</span>
      </div>
      <UnitRentAnalysisCard
        :unit="unit"
        class="mb-3"
      />
    </template>

    <div
      v-if="$viewport.gt.md"
      class="property-info-header font-17 font-weight-strong mb-4"
    >
      Property Info
    </div>
    <ElCard
      class="property-info-card px-3 py-1"
      shadow="never"
      :body-style="{ padding: 0, 'flex-direction': 'column' }"
    >
      <UnitCollapsableDetails
        :unit="unit"
        :unit-device="unitDevice"
        @open-dialog="openDeviceCodeDialog"
        @edit="$emit('edit')"
      />
    </ElCard>
    <div
      v-if="lastIntegrationSyncString"
      class="d-flex justify-content-center align-items-center p-1 text-center"
    >
      <span
        class="text-secondary font-13"
      >
        {{ lastIntegrationSyncString }}
      </span>
      <ElButton
        type="text"
        class="ml-2"
        size="small"
        :loading="uiFlags.isSyncing"
        @click="syncUnit"
      >
        {{ uiFlags.isSyncing ? 'Syncing' : 'Sync now' }}
      </ElButton>
    </div>
    <DeviceCodeDialog
      v-if="uiFlags.isDeviceCodeDialogVisible"
      :device="unitDevice"
      @close="deviceCodeDialogClose"
    />
  </components>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import momentUtil from '@/utils/MomentUtil';
import get from 'lodash.get';
import DeviceCodeDialog from '@/pages/App/Device/DeviceCodeDialog.vue';
import UnitRentAnalysisCard from '@/components/unit/UnitRentAnalysisCard.vue';
import { ShowingTypes } from '@/constants/UnitShowingProfile';
import UnitCollapsableDetails from './UnitCollapsableDetails';

export default {
  name: 'UnitInfo',
  components: {
    UnitRentAnalysisCard,
    UnitCollapsableDetails,
    DeviceCodeDialog,
  },
  props: {
    isPage: {
      type: Boolean,
    },
    isRenderedFromUnitPage: {
      type: Boolean,
    },
    unit: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const lastIntegrationSyncString = computed(computeLastIntegrationSyncString);
    const unitPicture = computed(computeUnitPicture);
    const unitDevice = computed(computeUnitDevice);
    const isPublishListing = get(props, 'unit.last_showing_profile.type') === ShowingTypes.PUBLISH_LISTING;
    const showingProfile = props.unit.last_showing_profile;

    const uiFlags = reactive({
      isDeviceCodeDialogVisible: false,
      isSyncing: false,
    });

    return {
      showingProfile,
      unitDevice,
      uiFlags,
      get,
      momentUtil,
      syncUnit,
      unitPicture,
      lastIntegrationSyncString,
      isPublishListing,
      deviceCodeDialogClose,
      openDeviceCodeDialog,
    };

    function openDeviceCodeDialog() {
      uiFlags.isDeviceCodeDialogVisible = true;
    }

    function deviceCodeDialogClose() {
      uiFlags.isDeviceCodeDialogVisible = false;
    }

    function computeLastIntegrationSyncString() {
      uiFlags.isSyncing = false;
      const syncDate = props.unit.origin_updated_at || props.unit.listing_synced_at || null;
      if (!syncDate) {
        return null;
      }

      const allowSync = props.unit.origin_id || props.unit.listing_identifier;

      if (!allowSync) {
        return null;
      }

      const lastIntegrationSync = momentUtil(props.unit.listing_synced_at, props.unit.property.timezone)
          .toDisplayFormatMonthWithDayAndShortTimeFormat();

      return `Last sync: ${lastIntegrationSync}`;
    }

    function computeUnitPicture() {
      if (props.unit?.property.picture_url) {
        return props.unit.property.picture_url;
      }
      return get(props, 'unit.images[0]');
    }

    async function syncUnit() {
      uiFlags.isSyncing = true;
      context.emit('sync');
    }

    function computeUnitDevice() {
      if (showingProfile && showingProfile.device && showingProfile.deleted_at === null) {
        const name = get(props, 'unit.device.name');
        const unit = get(props, 'unit');
        return {
          serial_number: showingProfile.device.serial_number,
          name,
          unit,
          provider: showingProfile.device.provider,
          id: showingProfile.device_id,
        };
      }
      return null;
    }
  },
};
</script>

<style lang="scss" scoped>
.property-info-header {
  display: flex;
  align-items: center;
  height: 40px;
}

.property-info-card {
  .property-card-top {
    position: relative;

    .property-image {
      display: inline-flex;
      position: relative;
      background-color: gray-color();
      min-height: 12.5rem;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3.5rem;
        background: linear-gradient(to top, gray-color(darker) 0%, transparent 100%);
      }

      img {
        height: 100%;
        position: absolute;
        bottom: 0;
        max-width: none;
      }

      &.no-pic {
        &::after {
          display: none;
        }
      }

      .sdicon-home {
        font-size: 1.8rem;
      }
    }
  }

  .unit-card-stats-wrap {
    border: 1px solid gray-color("light");
    margin: 0 -1px;
  }

  .daily-code {
    background: gray-color("light");
    border-radius: 3px;

    .code {
      letter-spacing: 0.5rem;
    }
  }
}

.grey-box {
  background: gray-color('lighter');
  border-radius: 0.25rem;
}

@media (max-width: 540px) {
  .property-info-box {
    border-bottom: 1px solid gray-color(light);
    margin-bottom: 1rem;
  }
}
</style>
